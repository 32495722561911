<template>
  <div class="row">
    <div class="col-12 bg-light mt-3">
      <b-carousel controls>
        <b-carousel-slide
          v-for="(b, i) in ratingsList"
          :key="i"
          class="h-100"
          style="min-height: 180px"
        >
          <div class="row h-100 no-padding justify-content-center">
            <div class="col-6 col-md" v-for="r in b" :key="r._id">
              <div class="text-center text-dark">
                 <!-- <b-form-rating
                  v-model="r.rating.note"
                  readonly
                  no-border
                  inline
                  size="sm"
                  variant="warning"
                ></b-form-rating> -->
                <b-icon icon="star-fill" variant="warning" class="mr-1" v-for="s in r.rating.note" :key="`star-${r._id}-${s}`"></b-icon>
                <div class="w-100 py-1"></div>
                <div class="font-italic font-weight-bold text-uppercase ">
                  {{ r.clientName }} - {{r.type}}
                </div>
                <small>{{ r.rating.comment || '- - -' }}</small>
                <div class="w-100 py-1"></div>
               
              </div>
            </div>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="col-3 p-2 d-none" v-for="r in ratings" :key="r._id">
      <div class="text-center">
        <div class="font-italic font-weight-bold text-uppercase">
          {{ r.clientName }}
        </div>
        <small>{{ r.rating.comment }}</small>
        <div class="w-100 py-1"></div>
        <b-form-rating
          v-model="r.rating.note"
          readonly
          no-border
          inline
          size="sm"
          variant="warning"
        ></b-form-rating>
      </div>

      <div class="w-100 py-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ratings: [],
    };
  },
  mounted() {
    console.log("loading feedback");
    this.getComments();
  },
  computed: {
    ratingsList() {
      // return array group by 3
      let arr = [];
      let temp = [];
      this.ratings.forEach((r, i) => {
        console.log({r, i})
        temp.push(r);
        if ((i + 1) % 2  === 0) {
          arr.push(temp);
          temp = [];
        }
      });
      console.log({ arr });
      return arr;
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    getComments() {
      if (this.info._id && !this.info.pages) {
        return this.$router.push(this.getLink("menu"));
      }
      let { webName } = this.$store.state.info;

      this.$http
        .get(`${webName}/rating`)
        .then((res) => {
          this.ratings = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>